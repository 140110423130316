<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { bankTypeEnum } from "@/components/enums.js";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Banka & Kasa Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Banka & Kasa Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Banka & Kasa Listesi",
          href: "/banks",
        },
        {
          text: "Banka & Kasa Ekle",
          active: true,
        },
      ],
      values: {
        bank: "",
        currency: 0,
        type: 0,
        acccount_holder: "",
        balance: 0,
      },
      currency: [],
      bankTypeEnum
    };
  },
  created() {
    this.getAllCurrency()
  },
  methods: {
    submit() {
      const api_url = process.env.VUE_APP_BASEURL + "/banks";
      const data = {
        bank: this.values.bank,
        currency: this.values.currency.id,
        type: this.values.type.value,
        acccount_holder: this.values.acccount_holder,
        balance: this.values.balance,
      } 
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/banks");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllCurrency() {
      const api_url = process.env.VUE_APP_BASEURL + "/exchange/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.currency = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-12">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="bank">Banka & Kasa</label>
                    <input v-model="values.bank" type="text" class="form-control" id="bank" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3">
                    <label for="acccount_holder">Hesap Adı</label>
                    <input v-model="values.acccount_holder" type="text" class="form-control" id="acccount_holder" />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group mb-3">
                    <label for="type">Hesap Türü</label>
                    <multiselect v-model="values.type" :options="bankTypeEnum" label="text" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-8 col-md-4">
                  <div class="form-group mb-3">
                    <label for="balance">Bakiye</label>
                    <input v-model="values.balance" type="double" class="form-control" id="balance" />
                  </div>
                </div>

                <div class="col-4 col-md-4">
                  <div class="form-group mb-3">
                    <label for="currency">Para Birimi</label>
                    <multiselect v-model="values.currency" :options="currency" label="name" :hover="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="d-flex justify-content-end align-items-center">
                    <button type="submit" class="btn btn-primary px-4">Kaydet</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
